import { Injectable, inject } from '@angular/core';
import { DataLayerService } from '../data-layer/data-layer.service';
import { RegisterInterestComponentName } from '@uc/web/shared/data-models';
import {
	determineTier,
	determineValue,
	isUserToNumber,
} from '../shared-functions/shared-functions';

@Injectable({ providedIn: 'root' })
export class RegisterInterestGTMService {
	private dataLayerSrv = inject(DataLayerService);

	click(componentName: RegisterInterestComponentName) {
		this.dataLayerSrv.push({
			eventName: 'register_interest_button_click',
			componentName: componentName,
			event: 'registerInterestClick',
		});
	}

	formStart(uniId: number, uniName: string) {
		this.dataLayerSrv.push({
			eventName: 'register_interest_form_start',
			university: uniId,
			universityName: uniName,
			event: 'registerInterestStart',
		});
	}

	success(timeToComplete: number, isUser: boolean, uniId: number, uniName: string) {
		const tier = determineTier(uniId);
		const value = determineValue(uniId);

		this.dataLayerSrv.push({
			eventName: 'register_interest_form_success',
			time: timeToComplete,
			tier: tier,
			value: value,
			user: isUserToNumber(isUser),
			university: uniId,
			universityName: uniName,
			event: 'registerInterestSuccess',
		});
	}
}
