import { NgClass } from '@angular/common';
import { Component, input } from '@angular/core';

@Component({
	selector: 'uc-character-count-error',
	standalone: true,
	imports: [NgClass],
	templateUrl: './character-count-error.component.html',
})
export class CharacterCountErrorComponent {
	hasLengthError = input<boolean>(false);
	charsLength = input<number>(0);
	limit = input<string>('');
}
