import { Component, Input } from '@angular/core';

import { InAppBrowserMessageHandlerService } from '@uc/web/core';

@Component({
	selector: 'uc-recaptcha-info',
	standalone: true,
	templateUrl: './recaptcha-info.component.html',
	imports: [],
})
export class RecaptchaInfoComponent {
	@Input() isInAppBrowser = false;

	constructor(
		private _inAppBrowserMessageHandlerSrv: InAppBrowserMessageHandlerService,
	) {}

	onPrivacyPolicy(link: string) {
		this._inAppBrowserMessageHandlerSrv.ios.onPrivacyPolicy(link);
		this._inAppBrowserMessageHandlerSrv.android.onPrivacyPolicy(link);
	}
}
