import {
	ChangeDetectionStrategy,
	Component,
	OnDestroy,
	OnInit,
	signal,
} from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { LoginResponseHandler, emailValidator } from '@uc/web/core';
import { ModalService } from '../modal/modal.service';

import { Role, AuthenticationService, errorLogin } from '@uc/shared/authentication';
import { CloseModalComponent } from '../close-modal/close-modal.component';
import { Router, RouterModule } from '@angular/router';
import { FormErrorComponent } from '../form-elements/form-error/form-error.component';
import { InputFieldComponent } from '../form-elements/input-field/input-field.component';
import { PasswordFieldComponent } from '../form-elements/password-field/password-field.component';
import { Store } from '@ngrx/store';

@Component({
	selector: 'uc-login-modal',
	standalone: true,
	templateUrl: './login-modal.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [
		PasswordFieldComponent,
		InputFieldComponent,
		FormErrorComponent,
		CloseModalComponent,
		ReactiveFormsModule,
		RouterModule,
	],
})
export class LoginModalComponent implements OnInit, OnDestroy {
	userSub!: Subscription;
	loginForm!: FormGroup;
	formSubmitted = false;
	formError = signal(false);
	isLoading = false;
	redirectToRegisterPage = true;

	private _initialTime = Date.now();
	private _loginHandler = new LoginResponseHandler();

	constructor(
		private _store: Store,
		private _router: Router,
		private _modalSrv: ModalService,
		private _authSrv: AuthenticationService,
	) {}

	ngOnInit(): void {
		this.loginForm = new FormGroup({
			loginEmail: new FormControl('', [Validators.required, emailValidator]),
			password: new FormControl('', [Validators.required]),
		});

		this.redirectToRegisterPage = !this._router.url.includes('degree-quiz/register');
	}

	ngOnDestroy(): void {
		if (this.userSub) this.userSub.unsubscribe();
	}

	onSubmit() {
		this.isLoading = true;
		this.formSubmitted = true;
		if (this.loginForm.invalid) return;

		const data = {
			email: this.loginForm.value.loginEmail,
			password: this.loginForm.value.password,
		};

		this._authSrv
			.login(data)
			.then((response) => {
				this.isLoading = false;
				this.closeModalPanel();

				if (response.role === Role.STUDENT) {
					this._loginHandler.handleUserResponse(
						response,
						data.email,
						this._initialTime,
					);
					// reload page if user signs in successfully (important for bookmark functionality)
					location.reload();
				} else if (response.role === Role.CLIENT) {
					this._loginHandler.handleClientResponse(response);
				}
			})
			.catch((error) => {
				this.isLoading = false;
				console.error(error.error);
				this._store.dispatch(errorLogin(error));
				this.formError.set(true);
			});
	}

	closeModalPanel() {
		this._modalSrv.close();
	}
}
