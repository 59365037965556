import { DOCUMENT, isPlatformServer } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class ScriptRemovalService {
	constructor(
		@Inject(DOCUMENT) private _document: Document,
		@Inject(PLATFORM_ID) private _platformId: string,
	) {}

	removeScripts(ids: string[]): void {
		if (isPlatformServer(this._platformId)) {
			ids?.forEach((id) => {
				const script = this._document.getElementById(id);
				if (script) {
					script.remove();
				}
			});
		}
	}
}
