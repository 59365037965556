export function isInAppBrowser(platform: string) {
	return (
		platform?.toLocaleLowerCase() === 'ios' ||
		platform?.toLocaleLowerCase() === 'android'
	);
}

export function normalizePlatformName(platform: string): 'iOS' | 'Android' | false {
	if (platform.toLocaleLowerCase() === 'ios') return 'iOS';
	else if (platform.toLocaleLowerCase() === 'android') return 'Android';
	else return false;
}
