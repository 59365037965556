This site is protected by reCAPTCHA and the Google
@if (isInAppBrowser) {
	<button
		(click)="onPrivacyPolicy('https://policies.google.com/privacy')"
		type="button"
		class="underline blue-link"
	>
		Privacy Policy
	</button>
	and
	<button
		(click)="onPrivacyPolicy('https://policies.google.com/terms')"
		type="button"
		class="underline blue-link"
	>
		Terms of Service
	</button>
} @else {
	<a
		href="https://policies.google.com/privacy"
		class="underline blue-link"
		target="_blank"
		rel="noopener"
		>Privacy Policy</a
	>
	and
	<a
		href="https://policies.google.com/terms"
		class="underline blue-link"
		target="_blank"
		rel="noopener"
		>Terms of Service</a
	>
}
apply.
